.hero {
    
    &__title {
        color: #fff;
    }

    &__subtitle {
        color: #fff;
    }

    &__cta {
        a {
            
        }
    }
}
