@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

//webchimp eigen components vanuit webchimp-base
//@import '../../../../webchimp-base/src/assets/scss/webchimp/menutoggle';
//@import '../../../../webchimp-base/src/assets/scss/webchimp/topbar';
//@import '../../../../webchimp-base/src/assets/scss/webchimp/webchimp';
//@import '../../../../webchimp-base/src/assets/scss/webchimp/utilities';


@include foundation-global-styles;
//@include foundation-grid;
//@include foundation-flex-grid;

@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/

// Global
@import "global/_accessibility";
@import "global/colors";
@import "global/wp-admin";
@import "global/wp-overrides";

// Modules
@import "modules/navigation";
@import "modules/content";
@import "modules/footer";
@import "modules/editor-style";

// Components
@import "components/buttons";
@import "components/cards";
@import "components/featured-image";
@import "components/dividers";
@import "components/links";

// Templates
@import "templates/front";
@import "templates/kitchen-sink";


// fonts
@import url("https://use.typekit.net/rie1pvi.css");

$brandcolor1: #1F2D35;
$brandcolor2: #CCAA60;
$brandcolor3: #CAD4D9;
$brandcolor4: #4A4A4A;
$brandcolor5: #E8EAEA;

// idTag
.idTag {
  span {
    display: none;
  }
}

.projecten {

}

body {
  padding-top: 54px;
}

.project {
  position: relative;

  .project--large {

  }

  &__image {
    margin-bottom: 1rem;
    
    @include breakpoint( medium ) {
      margin-bottom: 2rem;
    }

    img {
      width: 100% !important;
      max-width: none !important;
    }
  }

  &__title {
    position: absolute;
    display: inline-block;
    left: 1rem;
    bottom: 1rem;
    background: $brandcolor1;
    color: white;
    font-size: 14px;
    font-weight: normal;
    max-width: 60%;
    padding: 1rem;
    margin: 0;
  }


  &--card {
    background: white;
    box-shadow: 0 0 24px 0 rgba(0,0,0,.2);
    margin-bottom: 2rem;

    img {
      height: auto;  
    }

    .project__image {
      margin: 0 !important;
    }
    
    .project__title {
      position: relative;
      text-align: left;
      color: $brandcolor1;
      background: transparent;
      max-width: none;
      padding: 1rem;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
    }
  }
}


.section {
  padding: 2rem 1rem;
    
  @include breakpoint(medium) {
    padding: 3rem 1rem;
  }

  @include breakpoint(large) {
    padding: 5rem 1rem;
  }

  &--blue {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &--widgets {
    color: white;
    background: $brandcolor1;
  }

  &--topbar {
    padding: 1rem;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    transition: .4s;

    .off-canvas-content.is-open-top & {
      background: none;
      .topbar__phone {
        opacity: 0;
      }
    }
  }
  
  &--credits {
    background: white;
    color: $brandcolor4;
    padding: 1rem  1rem  .8rem 1rem;
  }

  &--cta {
    color: white;
    background: #424B51;
    background-image: radial-gradient(49% 145%, rgba(255,255,255,0.20) 45%, rgba(0,0,0,0.20) 100%);
    padding: 2rem 1rem 1rem 1rem;
  }

  &--review {
    background: $brandcolor3;

    @include breakpoint(medium) {
      padding: 2rem;
    }

    @include breakpoint(large) {
      padding: 3rem;
    }
  }

  &--beige {
    background: $brandcolor5;
  }

  &--intro {
    text-align: center;

    .elementor-column-wrap {
      margin: 0 auto;
      max-width: 680px;

      p:last-child {
        margin: 0;
      }
    }
  }


  &--pagination {
    background: $brandcolor1 url(../images/projecten-background.jpg) no-repeat;
    background-size: cover;
    color: white;
    padding: 2rem;

    .grid-container {
      margin-bottom: 2rem;
    }
    
    a {
      color: white;
    }

    img {
      border: 6px solid white;
    }

    .media-object-section:first-child {
      padding-right: 0;
      margin-right: 1rem;
    }
  }
}


@import '../../../../webchimp-base/src/assets/scss/webchimp/hero';


.hero {
  
  .elementor-widget-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 64px);
    max-height: 860px;
    text-align: center;
  }

}



// <header class="section  topbar  hide-on-small">

//         <a href="mailto:info@roskamdaktechniek.nl"  class="icon  icon--mail  topbar__email">info@roskamdaktechniek.nl</a>
    
//         <nav class="menu  show-for-medium  topbar__nav" role="navigation">
//             {% include "menu.twig" with {'menu': menu.get_items} %}
//         </nav>

//         <a href="tel:0625000041"  class="icon  icon--phone topbar__phone">+31 (0)6 25 00 00 41</a>

//         <a href="#" class="menutoggle  show-for-small-only  topbar__menutoggle" data-close  aria-label="Close menu" data-toggle="offCanvasID">
//             <div></div>
//         </a>
    
//     </header>



.menu {
  justify-content: center;
  
  a {
    padding: 0 1rem;
    line-height: 24px;
    color: $brandcolor1;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    transition: .5s;
    font-size: 14px;

    &:hover {
      color: lighten($brandcolor1, 50%);
    }
  }

  .current_page_item a:after {
    height: 2px;
    background: lighten($brandcolor1, 40%);
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 0;
    content: '';
    display: block;
  }
}

.menutoggle {
  display: block;
  position: relative;
  transition: .5s;
  width: 24px;
  height: 18px;
  z-index: 9999;
  transition: .4s;
  
  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    background: $brandcolor1;
    left: 0;
    top: 0;
    height: 2px;
    width: 100%;
    border-radius: 2px;
    transform-origin: center center;
    transition: .4s;
    transform: rotate(0deg);
  }
  
  &:after {
    top: 16px;
  }

  &:hover {

    &:before {
      top: 1px;
    }

    &:after {
      top: 15px;
    }

  }
  
  div {
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 9px;
    transform: translateY(-50%);
    background: $brandcolor1;
    height: 2px;
    width: 100%;
    opacity: 1;
    transition: .4s;
    transition-delay: .5s;
  }

  .off-canvas-content.is-open-top & {
      
    div {
      opacity: 0;
      background: white;
    }

    &:before,
    &:after {
      transition-delay: .5s;
      top: 9px;
      background: white;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }
}


.topbar {
  background: white;
  color: $brandcolor1;
  text-align:  center;
  line-height: 24px;
  height: 54px;

  .logged-in & {
    top: 32px;
  }

  &__nav {
    display: none;
    @include breakpoint(large) {
      display: block;
    }
  }

  &__email {
    font-weight: bold;
    display: none;
    color: $brandcolor2;

    @include breakpoint(large) {
      display: block;
      float: left;
    }
  }

  &__phone {
    font-weight: bold;
    float: left;
    display: block;
    color: $brandcolor2;

    @include breakpoint(large) {
      float: right;
    }
  }

  &__toggle {
    float: right;
    @include breakpoint(large) {
      display: none;
    }
  }
}

.callout {
  padding: 0;
  border: 0;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.2);
  margin-bottom: 2rem !important;

  figcaption {
    padding: 1rem;
  }
}


.elementor-container .hero {
    
  &__title {
      color: #fff;
  }

  &__subtitle {
      color: #fff;
  }

  &__cta {
      a {
        color: white;
        background: $brandcolor2;
        font-weight: bold;
        min-width: 288px;
        transition: .3s;
        padding: 17px 30px 13px 30px;
        
        &:hover {
          background: darken($brandcolor2, 5%);
        }
      }
  }
}

.mw-288 {
  min-width: 288px;
}

.button {

  &--gold {
    color: white;
    background: $brandcolor2;
    font-weight: bold;
    transition: .3s;
    padding: 17px 30px 13px 30px;
    border-radius: 4px;
    text-decoration: none !important;
    
    &:hover {
      background: darken($brandcolor2, 5%);
    }
  }

  &__nnn {

  }
}

.button {
  font-weight: bold;
  transition: .3s;
  padding: 17px 30px 13px 30px;
  text-decoration: none;

  &--gold {
    color: white;
    background: $brandcolor2;
    
    &:hover {
      background: darken($brandcolor2, 5%);
    }
  }

  &--dark {
    color: white;
    background: $brandcolor1;
    
    &:hover {
      opacity: .8;
    }
  }
}

.btn {

  &--gold {
    a {
      color: white;
      background: $brandcolor2;
      font-weight: bold;
      transition: .3s;
      padding: 17px 30px 13px 30px;
      
      &:hover {
        background: darken($brandcolor2, 5%);
      }
    }
  }

  &__dark {

  }
}

.icon {
  padding-left: 36px;

  &--mail {
    background: transparent url(../images/icon-mail.png) no-repeat left center;
    background-size: contain;
  }

  &--phone {
    background: transparent url(../images/icon-phone.png) no-repeat left center;
    background-size: contain;
  }
}




.elementor-container {

  .mediabox  {
    color: $brandcolor4;
  
    figure {
      text-align: center;
      margin-bottom: 0;
  
      img {
        width: 58px;
        margin-bottom: 0;
      }
    }
  
    .elementor-image-box-title {
      color: $brandcolor4;
      font-size: 20px;
      margin: 0;
    }
  
    &--cta {
      .elementor-image-box-title {
        color: white;  
      }

      figure {
        img {
          max-width: 90px;
        }
      }
    }


  }

}

.position-top {
  transform: translateY(-200vh);
}


// headers

.elementor-container {

  .panel-header {
    
    .elementor-heading-title {
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-weight: 600;
      letter-spacing: .005rem;
      border-radius: 4px;
    
      @include breakpoint(medium) {
        margin-bottom: 2rem;
        font-size: 28px;
      }
    
      @include breakpoint(medium) {
        margin-bottom: 3rem;
        font-size: 32px;
      }
    }

    &--gold .elementor-heading-title {
      color: $brandcolor2;
    }

    &--white .elementor-heading-title {
      color: white;
    }

    &--dark .elementor-heading-title {
      color: $brandcolor1;
    }

  }

}










.separator {
  
  &:after {
    content: '';
    background-size: contain;
    width: 60px;
    height: 66px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -30%);
    top: 0;
  }

  &--medium:after {
    background: url(../images/roskam-shield-separator-medium.png);
    background-size: contain;
  }

  &--dark:after {
    background: url(../images/roskam-shield-separator-dark.png);
    background-size: contain;
  }

  &--light:after {
    background: url(../images/roskam-shield-separator-light.png);
    background-size: contain;
  }

}

.site-footer {

  .footer-widgets {
    font-size: 15px;
    text-align: center;
  
    @include breakpoint(large) {
      text-align: left;
    }

    p {
      opacity: .7;
    }
  
    h4 {
      font-size: 16px;
      margin-bottom: 1rem;
    }
  
    a {
      color: white;
      text-decoration: underline;
    }


  }

  .footer-credits {
    font-size: 14px;
    color: $brandcolor1;

    a {
      color: $brandcolor1;
    }
  }

}

.footer-image {
  border-radius: 50%;
  width: 128px;
  height: 128px;
  margin: 0 auto 1rem auto;

  @include breakpoint(large) {
    float: left;
    margin-right: 24px;
    clear: both;
  }
}

.off-canvas {
  color: white;
  height: 100vh;
  background: $brandcolor1 url(../images/logo-roskam-daktechniek-diap.png) no-repeat center bottom 36px;
  background-size: 122px auto;
  display: flex;
  justify-content: center;
  text-align: center;

  .menu.vertical {
    margin-bottom: 148px;
  }

  a {
    color: white;
    font-size: 18px;
    line-height: 48px;
  }

}


.grid-container {

  .elementor-widget-image {
    
    
  }

  .elementor-widget-wrap {
    justify-content: center;
    @include xy-grid;

    .elementor-widget-image {
      margin: 0 auto 3rem auto;
      max-width: 360px;

      @include xy-cell(12);
  
      @include breakpoint(medium) {
        @include xy-cell(6);
      }

      @include breakpoint(large) {
        @include xy-cell(4);
      }

    }

  }

}



.grid-object-fit {
  .cell {
    padding-bottom: 2rem;

    .cell:nth-child(2) {
      padding-bottom: 0;
    }

    .project {
      height: 100%;
    }

    .project__image {
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.image-latent {
  opacity: 0;
  background-size: cover;
  background-position: center center;
}

.idTag {
  background: $brandcolor1;
  text-align: center;
  display: block;
  padding: 1rem;
  clear: both;

  img {
    max-height: 64px;

    @include breakpoint (medium) {
      max-height: 64px;
    }

    @include breakpoint (large) {
      max-height: 64px;
    }
  }
}


.pager {
  &__item {
    align-items: center;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.arrow {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 1rem;
    left: 50%;
    margin-left: -15px;
    width: 30px;
    height: 30px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
    background-size: contain;
    z-index: 10;
  }
}

.bounce:after {
  animation: bounce 2s infinite;
}


.webchimp-credit {
  position: absolute;
  right: 1rem;
  bottom: .6rem;
  display: block;
  background: url(../images/webchimp-heart.png) no-repeat center top;
  font-size: 0;  
  background-size: 30px auto;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  transition: .3s;
  opacity: .4;
  z-index: 10;

  &::after {
    content: "";
    background: url(../images/webchimp-icon-transparent.svg) no-repeat center center;
    background-size: cover;
    height: 0px;
    width: 0px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -2;
    transition: .4s;
    display: block;
    transform-origin: center bottom;
  }

  &::before {
    content: attr(data-title);
    background: #88C426    ;
    color: white;
    border-radius: 4px;
    position: absolute;
    right: 40px;
    top: 2px;
    max-width: 0px;
    overflow: hidden;
    display: block;
    transition: .4s;
    transition-delay: .4s;
    z-index: -2;
    font-size: 12px;
    white-space: nowrap;
    padding: 4px 0;
  }

  &:hover {
    background-position: center bottom;
    opacity: 1;

    &::before {
      max-width: 1000px;
      padding: 4px 16px;
    }

    &::after {
      top: -25px;
      animation: wave 2s linear infinite alternate;
    }
    
  }

}


// FLOWGRID ============================================

.flowgrid {

  .elementor-column-wrap {
    padding: 0 !important;
  }

  .elementor-widget-wrap {
    display: flex;
    flex-flow: row wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    width: auto;
    padding: 0;

    .elementor-element {
      width: 100%;
      margin-right: 1rem;
      margin-left: 1rem;
      margin-bottom: 1rem !important;

      @include breakpoint(medium) {
        width: calc(50% - 2rem);
      }

      @include breakpoint(large) {
        width: calc(33.33333% - 2rem);
      }
    }
  }
}